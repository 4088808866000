import { Country, Language } from '@/inc/types'

/**
 * Languages
 */
export const langDefault: Language = 'fr'
export const langAvailable = ['fr', 'nl'] as const
export const countryDefault: Country = 'be'
export const countryAvailable = ['be', 'lu'] as const

/**
 * URLs, segments, endpoints
 */
export const segments = {
  // eslint-disable-next-line
  // foo: ['bar', 'baz'],
  andersWonen: ['vivre-differemment', 'anders-wonen'],
  experience: ['reportage-client', 'klantenverhalen'],
  podcasts: ['podcasts', 'podcasts'],
  blog: ['blog', 'blog'],
  magazine: ['magazine', 'magazine'],
  mood: ['style', 'mood'],
  moods: ['styles-camber', 'moods'],
  category: ['categorie', 'category'],
  newsletter: ['newsletter', 'nieuwsbrief'],
  brochure: ['brochure', 'brochure'],
  job: ['job', 'job'],
  rdv: ['prendre-un-rendez-vous', 'maak-een-afspraak'],
  socialProjects: ['actions-solidaires', 'sociaal-engagements'],
  thanks: ['merci', 'bedankt'],
  welcome: ['bienvenuechezvous', 'welkomthuis'],
  moodboardBuilder: ['moodboard-builder'],
  // Uncomment this to make the inspirations/creations change effective:
  // creations: ['realisations', 'realisaties'],
  // realizations: ['inspirations', 'inspiraties'],
  // realization: ['inspiration', 'inspiratie'],
  // And remove this:
  // creations: ['realisations', 'realisaties'],
  realizations: ['realisations', 'realisaties'],
  realization: ['realisation', 'realisatie'],
} as Record<string, string | string[]>
export const endpointDefault = 'pages'

// API URLs
export const getApiUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`
export const getThumborUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_THUMBOR_PATH}`

/**
 * GTM IDs
 */
export const gtm = {
  production: 'GTM-NJ2779V',
  staging: 'GTM-NJ2779V',
}

/**
 * Options
 */
export const isUnderMaintenance = false
export const useCookiebar = false
export const favicon = {
  tabColor: '#fcaf17',
  tileColor: '#fcaf17',
  themeColor: '#ffffff',
}
